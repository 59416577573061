import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../widget/Header";
import Navbar from "../../../widget/Navbar";
import Footer from "../../../widget/Footer";
import ReactDatatable from "@mkikets/react-datatable";
import "react-confirm-alert/src/react-confirm-alert.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { getTask } from "../../../services/task";
import {Link} from "react-router-dom"

export const Task = () => {
  const [record, setRecord] = useState([]);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      className: "sr_no.",
      align: "left",
      sortable: true,
      
    },
    {
        key: "image",
        text: "Image",
        className: "color",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <img src={record?.image} alt=""/>
        },
      },
    {
      key: "description",
      text: "Description",
      className: "sr_no.",
      align: "left",
      sortable: true,
      
    },
    {
      key: "is_facebook",
      text: "Facebook",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_facebook==1?"Yes":"No"}</span>
      },
    },

    {
      key: "is_instagram",
      text: "Instagram",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_instagram==1?"Yes":"No"}</span>
      },
    },

    {
      key: "is_linkedin",
      text: "Linkedin",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_linkedin==1?"Yes":"No"}</span>
      },
    },

    {
      key: "is_twitter",
      text: "Twitter",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_twitter==1?"Yes":"No"}</span>
      },
    },
    {
      key: "is_discord",
      text: "Discord",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_discord==1?"Yes":"No"}</span>
      },
    },
    {
      key: "is_telegram",
      text: "Telegram",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.is_telegram==1?"Yes":"No"}</span>
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const getData = async () => {
    const resp = await getTask();
    if (resp.status) {
      setRecord(resp?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          {" "}
          <div className="d-flex justify-content-center"></div>
          <div className="section-heading d-flex justify-content-between">
            <h2>
              <b>Task List</b>
            </h2>
            <Link to="/add-task" className="btn btn-primary"> Add task</Link>
          </div>
          <div className="product-list-outer table-responsive">
            <ReactDatatable
              config={config}
              records={record}
              columns={columns}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
