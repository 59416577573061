import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addTask } from "../../../services/task";
import Footer from "../../../widget/Footer";
import Header from "../../../widget/Header";
import Navbar from "../../../widget/Navbar";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddTask() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [image, setImage] = useState("");
  const [points, setPoints] = useState(0);
  const [pointsErr, setPointsErr] = useState("");
  const [disable, setDisable] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [checkBoxField, setCheckBoxField] = useState({
    is_facebook: "0",
    is_linkedin: "0",
    is_twitter: "0",
    is_instagram: "0",
    is_discord: "0",
    is_telegram: "0",
  });

  const handleCheckBox = (e) => {
    const { name, value } = e.target;
    setCheckBoxField({ ...checkBoxField, [name]: value });
  };

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "description") {
      setDescription(value);
      const err = InputValid(name, value);
      setDescriptionErr(err);
    }
    if (name === "points") {
      setPoints(value);
      if (points === "") {
        setPointsErr("This field is required");
      } else {
        setPointsErr("");
      }
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "taskimage") {
      setImage(image);
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const checkName = InputValid("name", name);
    const checkDescription = InputValid("description", description);
    if (checkName) {
      setNameErr(checkName);
      return;
    }
    let checkImage = ImageValid("image", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }
    if (points === "") {
      setPointsErr("This field is required");
      return;
    }
    if (points == "0") {
      setPointsErr("Invalid points");
      return;
    }
    if (checkDescription) {
      setDescriptionErr(checkDescription);
      return;
    }
    setDisable(true);
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("points", points);
    formdata.append("task_image", image);
    formdata.append("is_facebook", checkBoxField.is_facebook);
    formdata.append("is_instagram", checkBoxField.is_instagram);
    formdata.append("is_linkedin", checkBoxField.is_linkedin);
    formdata.append("is_twitter", checkBoxField.is_twitter);
    formdata.append("is_telegram", checkBoxField.is_telegram);
    formdata.append("is_discord", checkBoxField.is_discord);
    const result = await addTask(formdata);
    if (result.status) {
      toast.dismiss();
      toast.success(result.message);

      setTimeout(() => {
        navigate("/task", { replace: true });
        setDisable(false);
      }, 2000);
    } else {
      setDisable(false);
      toast.dismiss();
      toast.error(result.message);
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading d-flex justify-content-between">
            <h2>
              <b>Add Task</b>
            </h2>
          </div>
          <div className="product-list-outer table-responsive">
            <form onSubmit={handelSubmit}>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    onChange={handlechange}
                    value={name}
                    className="form-control"
                    name="name"
                  />
                  <span style={{ color: "red" }}>{nameErr}</span>
                </div>
                <div className="col-md-6 mb-2">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    onChange={handlechangeimage}
                    className="form-control"
                    accept="image/png, image/jpeg, image/jpg"
                    name="taskimage"
                  />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>
                <div className="col-md-4 mb-2">
                  <label className="form-label">Points</label>
                  <input
                    type="number"
                    onChange={handlechange}
                    className="form-control"
                    name="points"
                  />
                  <span style={{ color: "red" }}>{pointsErr}</span>
                </div>
                <div className="col-md-8 mb-2">
                  <label className="form-label">Description</label>
                  {/* <textarea
                    value={description}
                    onChange={handlechange}
                    name="description"
                    className="form-control"
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                        ],
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>
                <div className="col-12">
                  <h2>Social Media</h2>
                </div>
                <div className="col-12 mb-2">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="intrestRate">
                            LinkeIn
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_linkedin === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_linkedin"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_linkedin === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_linkedin"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="intrestRate">
                            Instagram
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_instagram === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_instagram"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_instagram === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_instagram"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="intrestRate">
                            Discord
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_discord === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_discord"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_discord === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_discord"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="intrestRate">
                            FaceBook
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_facebook === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_facebook"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_facebook === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_facebook"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="loan">
                            Twitter
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_twitter === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_twitter"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_twitter === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_twitter"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-5">
                          <label className="form-label" htmlFor="loan">
                            TeleGram
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_telegram === "1"}
                                onChange={handleCheckBox}
                                value="1"
                                name="is_telegram"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                checked={checkBoxField.is_telegram === "0"}
                                onChange={handleCheckBox}
                                value="0"
                                name="is_telegram"
                                type="radio"
                                className="form-check-input me-2"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={disable}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AddTask;
