import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import { UserManagement } from "./components/pages/user/UserManagement";
import { useAuth } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./components/pages/dashboard/Dashboard";
import { Task } from "./components/pages/task/Task";
import AddTask from "./components/pages/task/AddTask";
import { UserTask } from "./components/pages/userTask/UserTask";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/user-management"
            element={<ProtectedRoute component={<UserManagement />} />}
          />
           <Route
            path="/task"
            element={<ProtectedRoute component={<Task />} />}
          />
          <Route
            path="/add-task"
            element={<ProtectedRoute component={<AddTask />} />}
          />
          <Route
            path="/user-task"
            element={<ProtectedRoute component={<UserTask />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
}
export default App;
