import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../widget/Header";
import Navbar from "../../../widget/Navbar";
import Footer from "../../../widget/Footer";
import ReactDatatable from "@mkikets/react-datatable";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { approveAllUserTask, approveUserTask, getUserTask } from "../../../services/userTask";
import { statusEnum } from "../../../Constent";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export const UserTask = () => {
  const [record, setRecord] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [data, setData] = useState([])
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (record && status) {
      const filterData = record?.filter((list) => {
        return list.status == status;
      });
      setData(filterData);
    }
  }, [record, status]);

  const userDatas = async () => {
    const resp = await getUserTask();
    if (resp.status) {
      setRecord(resp?.data);
    }
  };

  useEffect(() => {
    userDatas();
  }, []);


  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
        key: "task_name",
        text: "Task Name",
        className: "sr_no.",
        align: "left",
        sortable: true,
      },
    {
      key: "type",
      text: "Type",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "url",
      text: "Url",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "points",
      text: "Points",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: " Status",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    
    {
      key: "registerDate",
      text: "Register Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
    {
        key: "action",
        text: "Action",
        className: `Action  ${status}-a`,
        align: `left ${status}-a`,
        sortable: true,
        cell: (record) => {
          return (
            <>
              <button
                style={{
                  border: "1px solid #fff",
                  borderRadius: "3px",
                  background: "green",
                  padding: "0px 7px",
                }}
                onClick={() => {
                  updateAlert(record.id);
                }}
                disabled={disable}
              >
                <i className="mdi mdi-check-all" style={{ color: "#fff" }}></i>
              </button>
            </>
          );
        },
      },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {approveTask(id)},
        },
        {
          label: "No",
        },
      ],
    });
  };

  const pageChange = (pageData) => {};

  const handleStatus = (value) => {
    setStatus(value);
  };

  const approveTask = (id) => {
    const data = { id };
    setDisable(true);
    approveUserTask(data).then((result) => {
      if (result.status) {
        toast.dismiss();
        toast.success(result.message);
        setDisable(false);
        userDatas()
      } else {
        toast.dismiss();
        toast.error(result.message);
        setDisable(false);
      }
    });
  };


  const updateAllAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {approveAllTask()},
        },
        {
          label: "No",
        },
      ],
    });
  };

  const approveAllTask=async()=>{
    const data = {} ;
    setDisable(true);
    approveAllUserTask(data).then((result) => {
      if (result.status) {
        toast.dismiss();
        toast.success(result.message);
        setDisable(false);
        userDatas()
      } else {
        toast.dismiss();
        toast.error(result.message);
        setDisable(false);
      }
    });
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />

      <div className="main-content">
        <div className="page-content">
          {" "}
          <div className="d-flex justify-content-center"></div>
          <div className="section-heading d-flex justify-content-between">
            <h2>
              <b>User Task List</b>
            </h2>
            <div className="filter-button">
            {statusEnum?.map((list) => {
              return (
                <button
                  key={list.key}
                  className={list.value == status ? "active ms-2" : "ms-2"}
                  onClick={() => {
                    handleStatus(list.key);
                  }}
                >
                  {list.value}
                </button>
              );
            })}
            <button className="btn btn-primary ms-3" onClick={()=>{updateAllAlert()}} disabled={disable}>Approve All</button>
          </div>
          </div>
          <div className="product-list-outer table-responsive">
            <ReactDatatable
              config={config}
              records={data}
              columns={columns}
              onPageChange={pageChange}
            />
          </div>
        </div>
        <Footer />
     
      </div>
    </div>
  );
};
