import { approveAllUserTaskApi, approveUserTaskApi, getUserTaskApi } from "../Constent/Api";
import * as opsService from "./Ops";

export const getUserTask = async () => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.getData(getUserTaskApi, token);
  return result;
};

export const approveUserTask = async (data) => {
    const token = localStorage.getItem("jwtToken");
    let result = await opsService.postdata(approveUserTaskApi,data, token);
    return result;
  };


export const approveAllUserTask = async (data) => {
    const token = localStorage.getItem("jwtToken");
    let result = await opsService.postdata(approveAllUserTaskApi,data, token);
    return result;
  };  