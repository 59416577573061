import { addTaskApi, getTaskApi } from "../Constent/Api";
import * as opsService from "./Ops";

export const addTask = async (data) => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.postdata(addTaskApi, data, token);
  return result;
};

export const getTask = async () => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.getData(getTaskApi, token);
  return result;
};
