import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../widget/Header";
import Navbar from "../../../widget/Navbar";
import Footer from "../../../widget/Footer";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { getUserData } from "../../../services/user";
import moment from "moment";

export const UserManagement = () => {
  const [record, setRecord] = useState([]);


  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "sponser_wallet",
      text: "Sponser Wallet Address",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "phone_number",
      text: "Mobile Number",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "usdt_balance",
      text: "USDT",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "bnb_balance",
      text: "BNB",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "agreement",
      text: "Approval Status",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (row, index) => {
        return <>{row.agreement == 1 ? "Completed" : "Pending"}</>;
      },
    },
    
    {
      key: "registerDate",
      text: "Register Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  const userDatas = async () => {
    let config = localStorage.getItem("jwtToken");
    const resp = await getUserData(config);
    if (resp.status) {
      setRecord(resp?.data);
    }
  };
  useEffect(() => {
    userDatas();
  }, []);


  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />

      <div className="main-content">
        <div className="page-content">
          {" "}
          <div className="d-flex justify-content-center"></div>
          <div className="section-heading d-flex justify-content-between">
            <h2>
              <b>User List</b>
            </h2>
          </div>
          <div className="product-list-outer table-responsive">
            <ReactDatatable
              config={config}
              records={record}
              columns={columns}
              onPageChange={pageChange}
            />
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};
