import { BaseUrl } from "./baseUrl";

//================== Task ========================
export const addTaskApi=BaseUrl+"/add-task"
export const getTaskApi=BaseUrl+"/get-task"

//================ User Task ====================
export const getUserTaskApi=BaseUrl+"/get-user-task"
export const approveUserTaskApi=BaseUrl+"/approve-user-task"
export const approveAllUserTaskApi=BaseUrl+"/approve-all-user-task"
